/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductBaseSpec = params => axios({
    url: '/api/product/system/productBaseSpec/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductBaseSpec = params => axios({
    url: '/api/product/system/productBaseSpec/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductBaseSpec = params => axios({
    url: '/api/product/system/productBaseSpec/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductBaseSpec = params => axios({
    url:'/api/product/system/productBaseSpec/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductBaseSpec = params => axios({
    url: '/api/product/system/productBaseSpec/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
